import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import GatsbyImage from 'components/common/GatsbyImage';

import { parseBanner } from 'components/Banner/parsers';

import { IUsefulLinksData } from './models';
import './UsefulLinksPage.scss';

interface IUsefulLinksPageProps extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: IUsefulLinksData;
}

const UsefulLinksPage: FC<IUsefulLinksPageProps> = ({
  pageContext: { breadcrumbs },
  data: {
    usefulLinks: {
      usefulLinksBanner,
      linksSection,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      ogImage,
      lang,
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => (
  <Layout
    seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage }}
    header={header}
    footer={footer}
    siteSettings={siteSettings}
    covidBanner={covidBanner}
    newsletterPopupDefault={newsletterPopupDefault}
    newsletterPopupDiscount={newsletterPopupDiscount}
    newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
    disclaimerPopup={disclaimerPopup}
    searchPath={searchPath}
    brandSettings={brandSettings}
    legalPopup={legalPopup}
    langSettings={{
      currentLang: lang,
      langs,
    }}
  >
    <div className="useful-links-page">
      <Banner
        {...parseBanner(usefulLinksBanner?.[0]?.properties)}
        bgBannerColor={
          usefulLinksBanner?.[0]?.properties?.pageBannerBannerBgColor?.[0]?.properties?.colorPicker
            ?.label || 'blue'
        }
        breadcrumbs={breadcrumbs}
      />

      <Container fluid>
        <Row>
          <Col sm={12}>
            <ul className="useful-links-list">
              {linksSection?.map(({ properties }) => (
                <li key={properties?.link}>
                  <a href={properties?.link} target="_blank" rel="noreferrer">
                    {properties?.logo?.gatsbyImage?.childImageSharp ? (
                      <GatsbyImage
                        fluid={properties?.logo?.gatsbyImage?.childImageSharp?.fluid}
                        alt={properties?.logoAlt}
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    ) : null}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

export const query = graphql`
  query($lang: String!, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    ...FragmentCommonCompositions
    pageLangs: allUsefulLinks(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    usefulLinks(lang: { eq: $lang }) {
      link
      lang
      usefulLinksBanner {
        properties {
          ...FragmentBanner
        }
      }
      linksSection {
        properties {
          link
          ariaLabel
          logoAlt
          logo {
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      ...FragmentSeo
    }
  }
`;

export default UsefulLinksPage;
